import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';

import { Page, Section, Button, Container, Carousel3d } from 'src/components';
import paths from 'src/constants/paths';
import { Markdown } from 'src/kit';

export const query = graphql`
  query CaseStudyQuery($slug: String) {
    allGraphCmsCaseStudy {
      nodes {
        id
        slug
        location
        buttonLink
        people {
          id
          firstName
          lastName
          position
          location
        }
        image {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1.33)
        }
        themeImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1.33)
        }
        illustration {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1.33)
        }
      }
    }
    graphCmsCaseStudy(slug: { eq: $slug }) {
      id
      title
      body
      date
      slug
      color
      location
      buttonLink
      people {
        id
        firstName
        lastName
        position
        location
      }
      illustration {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 4000, aspectRatio: 1.77)
      }
      image {
        url
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 4000, aspectRatio: 1.77)
      }
      themeImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 4000, aspectRatio: 1.77)
      }
      video {
        id
        url
      }
    }
  }
`;

const SImageO = styled.div`
  width: 100%;
  background-color: var(--c-bg-gray);
`;

const SImgOuter = styled.div`
  margin: auto;
  margin-top: calc(var(--s-nav-height));
  max-height: calc(100vh - var(--s-nav-height) - 12px);
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  cursor: pointer;
  z-index: 0;
  overflow: hidden;
  > div {
    max-height: 100%;
    overflow: hidden;
  }
`;

/**
 * @todo
 * - city switcher
 * - layout
 * - markdown formatter
 * - sharing
 * - shared "more" component
 * - gatsby image
 */
const CaseStudyTemplate = ({ data }) => {
  const [playing, setPlaying] = useState(false);
  const video = useRef<HTMLVideoElement>();

  const post = data?.graphCmsCaseStudy;
  const person = post?.people?.[0];

  const visibleTitle =
    post.people?.[0]?.firstName +
    (post.people?.[1]?.firstName ? '&' + post.people?.[1]?.firstName : '');

  const multPeople = post.people?.length > 1;

  const _title = multPeople
    ? `${post?.people?.[0]?.firstName} & ${post?.people?.[1]?.firstName} in ${post?.location}`
    : `${person?.firstName}, ${person?.position} in ${post?.location}`;
  const _desc = `Catch Creatour Highlights`;

  const playVideo = () => {
    setPlaying(true);
    setTimeout(() => video.current?.play(), 150);
  };

  const img = post?.image?.gatsbyImageData;

  return (
    <Page
      twitter={person?.twitter}
      date={post?.date}
      type="article"
      title={_title}
      description={_desc}
      darkColor={'#000000'}
      image={getSrc(img)}
      video={post?.video?.url}
      navBorder={false}
    >
      <div>
        <SImageO>
          <SImgOuter onClick={!playing ? playVideo : () => {}}>
            <GatsbyImage
              image={img}
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                filter: 'blur(25px)',
                transform: 'scale(1.25)',
                opacity: 0.75,
              }}
            />

            <video
              onEnded={() => setPlaying(false)}
              className="fadeIn"
              controls={playing}
              autoPlay={playing}
              playsInline
              height="100%"
              width="100%"
              ref={video}
              style={videoStyle}
              poster={post?.image?.url}
            >
              <source src={post?.video?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div
              className="flex"
              style={{
                visibility: playing ? 'hidden' : 'visible',
                ...overlayStyle,
              }}
            >
              <img
                src={post?.illustration?.url}
                style={{
                  position: 'absolute',
                  width: '20%',
                  bottom: '-2rem',
                  left: '1rem',
                  mixBlendMode: 'screen',
                }}
              />
              <h1
                style={{
                  color: 'white',
                  margin: 0,
                  marginBottom: '8px',
                }}
              >
                {visibleTitle}
              </h1>
              <h3 style={{ color: 'white', margin: 0, marginBottom: '2rem', fontWeight: 400 }}>
                {person?.position}
              </h3>
              <Button onPress={playVideo} color="#FFFFFF99" textColor="black">
                {person ? `Watch the story` : 'RSVP'}
              </Button>
            </div>
          </SImgOuter>
        </SImageO>
      </div>
      <br />
      <Container>
        <style>{`article.article blockquote p { background-image: var(--g-${post?.color}-text) !important}`}</style>
        <article className="article narrow-text">
          <Markdown>{post?.body}</Markdown>
        </article>
      </Container>

      <Section center large small wide creatour title="Creatour">
        <Carousel3d
          items={data?.allGraphCmsCaseStudy?.nodes?.map((post) => ({
            title:
              post.people?.[0]?.firstName +
              (post.people?.[1]?.firstName ? ' & ' + post.people?.[1]?.firstName : ''),
            subtitle: post?.people?.[0]?.position,
            image: post?.image?.gatsbyImageData || post?.themeImage?.gatsbyImageData,
            path: `${paths.CREATOR}/${post.slug}`,
            color: post.color,
          }))}
        />
      </Section>
    </Page>
  );
};

export default CaseStudyTemplate;

const overlayStyle = {
  position: 'absolute',
  zIndex: 9999999,
  margin: 'auto',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
  background: 'linear-gradient(180deg, #00000000 0%, #00000099 100%)',
  padding: '0 0 5vw',
};

const videoStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 999999,
};
